(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider").owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1.5,
        },
        576: {
          items: 2.65,
        },
        768: {
          items: 2.65,
        },
        992: {
          items: 4.65,
        },
        1200: {
          items: 4.65,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    $(window).scroll(function () {
      var windowWidth = $(window).width();
      var $el = $("body").add(".header");
      if ($(window).scrollTop() > 0) {
        $el.addClass("sticky");
      } else {
        $el.removeClass("sticky");
      }
    });

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 0);
      }
    });

    // faq fix
    let faqlinks = document.querySelectorAll(".faq-category a");
    faqlinks.forEach((link) => {
      if (link.id) {
        link.id = "";
        link.href = "#";
      }
    });

    // prevent scrolling on #TommyBookingSupport page
    $(window).on("hashchange", function (e) {
      if ($("#TommyBookingSupport").length > 0) {
        e.preventDefault();

        // disable scrolling
        $("body").css("overflow", "hidden");

        // set current scroll position
        $("html, body").animate({
          scrollTop: $("#TommyBookingSupport").offset().top - 120,
        });

        // enable scrolling
        setTimeout(() => {
          $("body").css("overflow", "auto");
        }, 10);
      }
    });
  });
})(jQuery);

// scrollbar eyecatcher
let eyeParent = document.querySelector("div.scrollbar");
let eyeUl = document.querySelector("ul.scrollbar");

let uspParent = document.querySelector(".home-usp-section div.usps");
let uspUl = document.querySelector(".home-usp-section ul.usps");

let allScrollbars = [
  { parent: eyeParent, ul: eyeUl },
  // { parent: uspParent, ul: uspUl },
];

function scrollBar(parent, ul) {
  if (parent && ul) {
    ulWidth = ul.getBoundingClientRect().width;
    parentWidth = parent.getBoundingClientRect().width;
    nmOfUls = Math.ceil(parentWidth / ulWidth);

    for (let i = -1; i < nmOfUls; i++) {
      let newUl = ul.cloneNode(true);
      newUl.classList.add("clone");
      parent.appendChild(newUl);
    }
  }
}
allScrollbars.forEach((bar) => {
  scrollBar(bar.parent, bar.ul);
});

// slider margin

let container = document.querySelector(
  ".home-intro-section.second .container.grid.second"
);
let sliderContainer = document.querySelector(
  ".home-intro-section.second .grid.second .slider"
);

function setSlider() {
  setTimeout(() => {
    if (container && !device.mobile()) {
      let windowWidth = window.innerWidth;
      let containerWidth = container.getBoundingClientRect().width;

      let containerDifference = (windowWidth - containerWidth) / 2;

      let newContainerWidth = containerWidth + containerDifference;
      container.style.maxWidth = newContainerWidth + "px";
      container.style.width = newContainerWidth + "px";
    }
  }, 25);
}

document.addEventListener("DOMContentLoaded", setSlider());
